var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-new-budget","title":"New Manage Budget","hide-footer":"","centered":"","size":"lg"},on:{"hidden":_vm.resetModal}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_vm._v(" Total "),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateGrandTotal, { symbol: 'Rp', precision: 0, thousand: '.' }))+" ("+_vm._s(_vm.calculatePercentage(_vm.calculateGrandTotal))+"%) ")])]),_c('b-col',{staticClass:"pl-3"},[_vm._v(" Remaining "),_c('div',{staticClass:"remaining"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.calculateRemaining, { symbol: 'Rp', precision: 0, thousand: '.' }))+" ("+_vm._s(_vm.calculatePercentage(_vm.calculateRemaining))+"%) ")])])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Year","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"text","clearable":false,"options":_vm.years,"placeholder":"Select year"},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Projection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Projection","invalid-feedback":errors[0]}},[_c('b-input-group',{class:{ 'is-invalid': errors.length },attrs:{"prepend":"Rp"}},[_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },on:{"input":function($event){return _vm.restrictInput($event)}},model:{value:(_vm.detail.projection),callback:function ($$v) {_vm.$set(_vm.detail, "projection", $$v)},expression:"detail.projection"}})],1)],1)]}}])})],1),_c('b-col',{staticClass:"mt-2 d-flex align-items-center",attrs:{"cols":"3"}},[_c('b-button',{staticClass:"reset",attrs:{"variant":"outline-warning","block":""},on:{"click":_vm.openModal}},[_vm._v(" Calculate ")])],1)],1),_c('b-table',{attrs:{"items":_vm.detail.budgetTypes,"fields":_vm.fields,"striped":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{staticClass:"spinner-lg",attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"cell(no)",fn:function(row){return [_c('b-link',{on:{"click":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}},[(!row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/add-toggle.svg")}}):_vm._e(),(row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/collaps-toggle.svg")}}):_vm._e()])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-table',{attrs:{"items":item.budgetActivities,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{staticClass:"spinner-lg",attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(typeName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.budgetActivityName)+" ")]}},{key:"cell(budgetPercent)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('validation-provider',{attrs:{"name":"percent","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.budgetPercentage),expression:"item.budgetPercentage"}],staticClass:"form-control text-center",class:{ 'is-invalid': errors.length },attrs:{"type":"text"},domProps:{"value":(item.budgetPercentage)},on:{"change":function($event){return _vm.changeAmount(item)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "budgetPercentage", $event.target.value)},function($event){return _vm.restrictInputPercent($event, index)}]}})])]}}],null,true)})]}},{key:"cell(budgetRp)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',[_c('money',{staticClass:"form-control text-center",on:{"input":function($event){return _vm.restrictInputAmount($event, item)}},model:{value:(item.budgetAmount),callback:function ($$v) {_vm.$set(item, "budgetAmount", $$v)},expression:"item.budgetAmount"}})],1)]}}],null,true)})]}},{key:"cell(budgetPercent)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalBudgetPercent(item.budgetActivities))+" % ")]}},{key:"cell(budgetRp)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(_vm.totalBudgetAmount(item.budgetActivities), { symbol: 'Rp ', precision: 0, thousand: '.' }))+" ")]}}])}),_c('b-row',{staticClass:"mt-4 mb-1"},[_c('b-col',[_c('b-button',{staticClass:"reset",attrs:{"variant":"outline-warning","block":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1),_c('b-col',[_c('b-button',{staticClass:"next",attrs:{"variant":"warning","block":""},on:{"click":_vm.saveBudget}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }