<template>
  <b-modal
    id="modal-new-budget"
    title="New Manage Budget"
    hide-footer
    centered
    size="lg"
    @hidden="resetModal"
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col cols="7">
          <b-row>
            <b-col cols="5">
              Total
              <div class="total">
                {{ formatMoney(calculateGrandTotal, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
                ({{ calculatePercentage(calculateGrandTotal) }}%)
              </div>
            </b-col>
            <b-col class="pl-3">
              Remaining
              <div class="remaining">
                {{ formatMoney(calculateRemaining, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
                ({{ calculatePercentage(calculateRemaining) }}%)
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="mt-1">
          <validation-provider
            name="Year"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Year"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="form.year"
                label="text"
                :clearable="false"
                :options="years"
                placeholder="Select year"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Projection"
            rules='required'
            v-slot="{ errors }"
          >
            <b-form-group
              label="Projection"
              :invalid-feedback="errors[0]"
            >
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                <money
                  class="form-control"
                  v-model="detail.projection"
                  @input="restrictInput($event)"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3" class="mt-2 d-flex align-items-center">
          <b-button
            class="reset"
            variant="outline-warning"
            block
            @click="openModal"
          >
            Calculate
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :items="detail.budgetTypes"
        :fields="fields"
        striped
        responsive
      >
        <template v-slot:table-busy>
          <div class="text-center my-4">
            <b-spinner
              variant="primary"
              class="spinner-lg"
            />
          </div>
        </template>
        <template #cell(no)="row">
          <b-link
            v-model="row.detailsShowing" @click="row.toggleDetails"
          >
            <img v-if="!row.detailsShowing" src="@/assets/add-toggle.svg"/>
            <img v-if="row.detailsShowing" src="@/assets/collaps-toggle.svg"/>
          </b-link>
        </template>
        <template #row-details="{ item }">
          <b-table
            :items="item.budgetActivities"
            :fields="fields"
            responsive
          >
            <template v-slot:table-busy>
              <div class="text-center my-4">
                <b-spinner
                  variant="primary"
                  class="spinner-lg"
                />
              </div>
            </template>
            <template #cell(no)="{ item, index }">
              {{ index + 1 }}
            </template>
            <template #cell(typeName)="{ item }">
              {{ item.budgetActivityName }}
            </template>
            <template #cell(budgetPercent)="{ item, index }">
              <validation-provider
                name="percent"
                rules="required|min_value:0|max_value:100"
                v-slot="{ errors }"
              >
                <b-form-group
                  :invalid-feedback="errors[0]"
                >
                  <input
                    class="form-control text-center"
                    v-model="item.budgetPercentage"
                    type="text"
                    @change="changeAmount(item)"
                    @input="restrictInputPercent($event, index)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </template>
            <template #cell(budgetRp)="{ item }">
              <b-form-group>
                <money
                  class="form-control text-center"
                  v-model="item.budgetAmount"
                  @input="restrictInputAmount($event, item)"
                />
              </b-form-group>
            </template>
          </b-table>
        </template>
        <template #cell(budgetPercent)="{ item }">
          {{ totalBudgetPercent(item.budgetActivities) }} %
        </template>
        <template #cell(budgetRp)="{ item }">
          {{ formatMoney(totalBudgetAmount(item.budgetActivities), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
        </template>
      </b-table>
      <b-row class="mt-4 mb-1">
        <b-col>
          <b-button
            class="reset"
            variant="outline-warning"
            block
            @click="cancel"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="next"
            variant="warning"
            block
            @click="saveBudget"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'

export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'typeName', label: 'Activities', thClass: 'text-center' },
      { key: 'budgetPercent', label: 'Budget(%)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'budgetRp', label: 'Budget(Rp)', tdClass: 'text-center', thClass: 'text-center' }
    ],
    items: [],
    years: [],
    form: {
      year: null,
      projection: 0
    }
  }),

  computed: {
    calculateGrandTotal() {
      const budgetActivities = this.detail.budgetTypes.map(v => v.budgetActivities)
      let total = 0
      budgetActivities.map(v => {
        v.map(d => {
          total += d.budgetAmount
        })
      })
      return total
    },
    calculateRemaining() {
      return this.detail.projection !== 0 ? this.detail.projection - this.calculateGrandTotal : 0
    }
  },

  created() {
    this.fetchYears()
  },

  methods: {
    formatMoney,
    openModal() {
      this.$bvModal.show('modal-calculate')
    },
    fetchYears() {
      let year = new Date().getFullYear()
      for (let i = 0; i < 5; i++) {
        this.years[i] = year++
      }
    },
    restrictInput(e) {
      if (e < 0 || this.detail.projection.toString().includes('-')) {
        const str = this.detail.projection.toString().replace('-', '')
        this.detail.projection = Number(str)
      }
    },
    restrictInputPercent(event, index, item) {
      if (/^\d*[.]?\d*$/.test(event.target.value)) {
        return true
      } else {
        this.detail.budgetTypes.map(v => {
          v.budgetActivities.forEach((d, i) => {
            if (i === index) {
              d.budgetPercentage = Number(d.budgetPercentage.toString().slice(0, -1))
            }
          })
        })
      }
    },
    changeAmount(item) {
      item.budgetAmount = this.detail.projection !== 0 ? item.budgetPercentage * this.detail.projection / 100 : 0
    },
    restrictInputAmount(e, item) {
      this.detail.budgetTypes.map(v => {
        v.budgetActivities.forEach(d => {
          if (e < 0 || d.budgetAmount.toString().includes('-')) {
            const str = d.budgetAmount.toString().replace('-', '')
            d.budgetAmount = Number(str)
          }
        })
      })
      const val = this.detail.projection !== 0 ? item.budgetAmount * 100 / this.detail.projection : 0
      item.budgetPercentage = val % 1 !== 0 ? val.toFixed(2) : val
    },
    totalBudgetPercent(item) {
      if (!item.length) return 0
      let total = 0
      item.map(v => {
        total += Number(v.budgetPercentage)
      })
      return total % 1 !== 0 ? total.toFixed(2) : total
    },
    totalBudgetAmount(item) {
      if (!item.length) return 0
      let total = 0
      item.map(v => {
        total += v.budgetAmount
      })
      return total % 1 !== 0 ? total.toFixed(2) : total
    },
    calculatePercentage(item) {
      const percent = this.detail.projection !== 0 ? item * 100 / this.detail.projection : 0
      return percent % 1 !== 0 ? percent.toFixed(2) : percent
    },
    async saveBudget() {
      try {
        this.isBusy = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        this.form.projection = this.detail.projection
        this.form.budgetTypes = this.detail.budgetTypes

        await api.budget.save(this.form)
        this.$bvToast.toast('Success save new budget.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
      this.resetModal()
      this.cancel()
      this.$emit('refresh')
    },
    resetModal() {
      this.form = this.$options.data().form
    },
    cancel() {
      this.$bvModal.hide('modal-new-budget')
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2F80ED;
}

.remaining {
  color: #F7AC26;
}
</style>
