<template>
  <b-modal
    id="modal-view-budget"
    title="View Manage Budget"
    hide-footer
    centered
    size="lg"
  >
    <b-row>
      <b-col cols="7">
        <b-row>
          <b-col cols="5">
            Total
            <div class="total">
              {{ formatMoney(calculateGrandTotal, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
              ({{ calculatePercentage(calculateGrandTotal) }}%)
            </div>
          </b-col>
          <b-col class="pl-3">
            Remaining
            <div class="remaining">
              {{ formatMoney(calculateRemaining, { symbol: 'Rp', precision: 0, thousand: '.' }) }}
              ({{ calculatePercentage(calculateRemaining) }}%)
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-row>
          <b-col>
            Year
            <div class="font-weight-bold">{{ detail.year }}</div>
          </b-col>
          <b-col>
            Projection
            <div class="projection">{{ formatMoney(detail.projection, { symbol: 'Rp', precision: 0, thousand: '.' }) }}</div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="d-flex align-items-center text-center">
        <b-row>
          <b-col>
            <b-link
              class="download"
              @click="fetchDownload(detail.id, 'PDF', detail.year)"
            >
              Download as .pdf
              <img
                src="@/assets/fi_download_blue.png"
                height="15"
              >
            </b-link>
          </b-col>
          <b-col>
            <b-link
              class="download"
              @click="fetchDownload(detail.id, 'XLS', detail.year)"
            >
              Download as .xls
              <img
                src="@/assets/fi_download_blue.png"
                height="15"
              >
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-table
      :items="detail.budgetTypes"
      table-class="table-budget-activity"
      :fields="fields"
      striped
      responsive
    >
      <template v-slot:table-busy>
        <div class="text-center my-4">
          <b-spinner
            variant="primary"
            class="spinner-lg"
          />
        </div>
      </template>
      <template #cell(no)="row">
        <b-link
          v-model="row.detailsShowing" @click="row.toggleDetails"
        >
          <img v-if="!row.detailsShowing" src="@/assets/add-toggle.svg"/>
          <img v-if="row.detailsShowing" src="@/assets/collaps-toggle.svg"/>
        </b-link>
      </template>
      <template #row-details="{ item }">
        <b-table
          :items="item.budgetActivities"
          :fields="fields"
          responsive
        >
          <template v-slot:table-busy>
            <div class="text-center my-4">
              <b-spinner
                variant="primary"
                class="spinner-lg"
              />
            </div>
          </template>
          <template #cell(no)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(typeName)="{ item }">
            {{ item.budgetActivityName }}
          </template>
          <template #cell(budgetPercent)="{ item }">
            {{ item.budgetPercentage }} %
          </template>
          <template #cell(budgetRp)="{ item }">
            {{ formatMoney(item.budgetAmount, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(utilized)="{ item }">
            {{ formatMoney(item.utilized, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(remaining)="{ item }">
            {{ formatMoney(item.remaining, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(january)="{ item }">
            {{ formatMoney(item.january, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(february)="{ item }">
            {{ formatMoney(item.february, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(march)="{ item }">
            {{ formatMoney(item.march, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(april)="{ item }">
            {{ formatMoney(item.april, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(may)="{ item }">
            {{ formatMoney(item.may, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(june)="{ item }">
            {{ formatMoney(item.june, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(july)="{ item }">
            {{ formatMoney(item.july, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(august)="{ item }">
            {{ formatMoney(item.august, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(september)="{ item }">
            {{ formatMoney(item.september, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(october)="{ item }">
            {{ formatMoney(item.october, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(november)="{ item }">
            {{ formatMoney(item.november, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
          <template #cell(december)="{ item }">
            {{ formatMoney(item.december, { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
          </template>
        </b-table>
      </template>
      <template #cell(budgetPercent)="{ item }">
        {{ calculateTotal(item.budgetActivities) }} %
      </template>
      <template #cell(budgetRp)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(budgetPercent)="{ item }">
        {{ calculateTotal(item.budgetActivities, 'budgetPercentage') }} %
      </template>
      <template #cell(budgetRp)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'budgetAmount'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(utilized)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'utilized'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(remaining)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'remaining'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(january)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'january'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(february)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'february'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(march)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'march'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(april)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'april'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(may)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'may'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(june)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'june'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(july)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'july'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(august)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'august'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(september)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'september'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(october)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'october'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(november)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'november'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(december)="{ item }">
        {{ formatMoney(calculateTotal(item.budgetActivities, 'december'), { symbol: 'Rp ', precision: 0, thousand: '.' }) }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import moment from 'moment'

export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    profile: JSON.parse(localStorage.getItem('me')),
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'typeName', label: 'Activities', thClass: 'text-center', thStyle: 'width: 200px' },
      { key: 'budgetPercent', label: 'Budget(%)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'budgetRp', label: 'Budget(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'utilized', label: 'Utillized(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'remaining', label: 'Remaining(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'january', label: 'January(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'february', label: 'February(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'march', label: 'March(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'april', label: 'April(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'may', label: 'May(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'june', label: 'June(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'july', label: 'July(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'august', label: 'August(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'september', label: 'September(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'october', label: 'October(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'november', label: 'November(Rp)', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'december', label: 'December(Rp)', tdClass: 'text-center', thClass: 'text-center' }
    ],
    items: []
  }),

  computed: {
    calculateGrandTotal() {
      const budgetActivities = this.detail.budgetTypes.map(v => v.budgetActivities)
      let total = 0
      budgetActivities.map(v => {
        v.map(d => {
          total += d.budgetAmount
        })
      })
      return total
    },
    calculateRemaining() {
      return this.detail.projection !== 0 ? this.detail.projection - this.calculateGrandTotal : 0
    }
  },

  created() {
  },

  methods: {
    formatMoney,
    async fetchDownload(id, type, year) {
      const response = type === 'PDF' ? await api.budget.downloadPdf(id) : await api.budget.downloadExcel(id)
      const url = URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      )
      const link = document.createElement('a')
      const time = moment().format('DD-MM-YYYY HH mm ss')
      link.href = url
      link.setAttribute('download', `Budget${year}_${time}_By${this.profile.fullname}`)
      document.body.appendChild(link)
      link.click()
    },
    calculateTotal(item, key) {
      if (!item.length) return 0
      let total = 0
      item.map(v => {
        total += v[key]
      })
      return total
    },
    calculatePercentage(item) {
      return this.detail.projection !== 0 ? item * 100 / this.detail.projection : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  color: #2F80ED;
}

.remaining {
  color: #F7AC26;
}

.projection {
  color: #4CAF50;
}

.download {
  color: #0057FF;
}
</style>
