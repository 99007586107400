<template>
  <div>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="d-flex justify-content-between">
        <b-button
          class="next"
          variant="warning"
          @click="openModal"
        >
          Add Manage Budget
        </b-button>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="keyword"
              placeholder="Search..."
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br/>
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png"/>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchBudgeting"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template v-slot:table-busy>
          <div class="text-center my-4">
            <b-spinner
              variant="primary"
              class="spinner-lg"
            />
          </div>
        </template>

        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(projection)="{ item }">
          {{ formatMoney(item.projection, { symbol: '', precision: 0, thousand: '.' }) }}
        </template>

        <template #cell(remaining)="{ item }">
          {{ formatMoney(item.remaining, { symbol: '', precision: 0, thousand: '.' }) }}
        </template>

        <template #cell(action)="{ item }">
          <b-link
            class="mr-2"
            @click="goToEdit(item.id)"
          >
            <fa-icon class="text-muted" icon="edit" />
          </b-link>
          <b-link
            @click="goToView(item)"
          >
            <fa-icon class="text-muted" icon="eye" />
          </b-link>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
          </span >
        </div>
      </div>
    </b-card>
    <modal-view-budget :detail="detail" />
    <modal-new-budget :detail="detail" @refresh="$refs.table.refresh()" />
    <modal-calculate @set="setDetail" />
  </div>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import ModalCalculate from './ModalCalculate.vue'
import ModalViewBudget from './ModalViewBudget.vue'
import ModalNewBudget from './ModalNewBudget.vue'

export default {
  props: {
  },

  components: {
    ModalCalculate,
    ModalViewBudget,
    ModalNewBudget
  },

  data: () => ({
    keyword: '',
    sortBy: '',
    busy: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'year', label: 'Year', tdClass: 'text-center', thClass: 'text-center', sortable: false },
      { key: 'projection', label: 'Projection(Rp)', tdClass: 'text-center', thClass: 'text-center', sortable: false },
      { key: 'utilized', tdClass: 'text-center', thClass: 'text-center', sortable: false },
      { key: 'remaining', tdClass: 'text-center', thClass: 'text-center', sortable: false },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    items: [],
    pageOptions: [10, 20, 50, 100],
    detail: {
      year: null,
      projection: 0,
      budgetTypes: []
    }
  }),

  computed: {},

  created() {},

  methods: {
    formatMoney,
    async fetchBudgeting() {
      const { data } = await api.budget.budgetingList({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: `year==${this.keyword}`,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage

      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async openModal() {
      await this.fetchViewBudget()
      this.detail.budgetTypes.map((v, i) => {
        v.budgetActivities.forEach(v => {
          v.budgetPercentage = 0
          v.budgetAmount = 0
        })
      })
      this.$bvModal.show('modal-new-budget')
    },
    goToEdit(id) {
      this.$router.push(`/budgeting/${id}`)
    },
    goToView(item) {
      this.fetchViewBudget(item.id)
      this.$bvModal.show('modal-view-budget')
    },
    async fetchViewBudget(id) {
      const { data } = id ? await api.budget.viewBudget(id) : await api.budget.budgetDefault()
      this.detail = data.data
      this.detail.budgetTypes.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
    },
    async setDetail(form) {
      await this.fetchViewBudget(form.id)
      this.detail.projection = form.projection
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
</style>
